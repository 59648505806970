<template>
  <div>
    <div class="phone-viewport">
      <md-bottom-bar md-sync-route>
        <md-bottom-bar-item to="/" exact md-label="Map" md-icon="place"></md-bottom-bar-item>
        <md-bottom-bar-item to="/autour" disabled md-label="Autour de moi (soon)" md-icon="navigation"></md-bottom-bar-item>
        <md-bottom-bar-item to="/favorites" disabled  md-label="Favorites (soon)" md-icon="favorite"></md-bottom-bar-item>
      </md-bottom-bar>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BottomBar'
  }
</script>

<style scoped>
  .phone-viewport {
    width: 100%;
    position: fixed;
    z-index: 6789;
    bottom: 0;
  }
</style>