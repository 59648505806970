<template>
  <div class="home">
    <NavBar/>
    <Map/>
    <BottomBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'
import Map from '@/components/Map.vue'

export default {
  name: 'Home',
  components: {
    BottomBar,
    NavBar,
    Map
  }
}
</script>
