const api = require('@/libs/api')

module.exports = {
    async getLocation() {
        const result = await new Promise(resolve => {
            navigator.geolocation.getCurrentPosition(d=>{
              resolve({
                lat: d.coords.latitude,
                lon: d.coords.longitude
              })
            })
        })
        return result
    },

    async getAll() {
        const all = await api.getAll()
        return all
    },

    order(all, pos) {
        for(let p of all) {
            p.distance = Math.round(this.getDistance(pos.lat, pos.lon, p.x, p.y)*10)/10
        }
  
        all.sort( ( a, b ) => {
            if ( a.distance < b.distance ){
              return -1
            }
            if ( a.distance > b.distance ){
              return 1
            }
            return 0
        })
    },

    getDistance(lat1,lon1,lat2,lon2) {
        function deg2rad(deg) {
            return deg * (Math.PI/180)
        }
        const R = 6371
        const dLat = deg2rad(lat2-lat1)
        const dLon = deg2rad(lon2-lon1)
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
        const d = R * c
        return d
    },
    formatUrl(short) {
        return short
            .replaceAll(' ', '-')
            .replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'_')
            .toLowerCase()
    }
}