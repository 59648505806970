<template>
  <div>
    <Loader v-if="data === {}"/>
    <div style="background-color: white; padding: 50px; border-radius: 15px; margin-top: 150px;">
      <h1>{{data.short}}</h1>
      <p>{{data.description}}</p>
      <p>{{data.distance}}km de vous</p>
      <center>
        <div @click="share" id="btnBack">Partager</div><br/>
        <div @click="back" id="btnBack">Retour</div>
      </center>
    </div>
  </div>
</template>

<script>
  import store from "@/store/index"
  import Loader from '@/components/Loader.vue'
  const api = require('@/libs/api')

  export default {
    name: 'Profile',
    data() {
      return {
        data: {},
        current: null,
        pos: {
          lat: null,
          lon: null
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      async init() {
        this.current = parseInt(this.$route.params.id)
        await this.getPosition()
        const data = await api.getAll()
        const result = data.find(x=>{
          return x.id === this.current
        })
        if(!result) {
          window.location.href = "/404"
        }else{
          this.data = result
        }
        this.data.distance = Math.round(this.getDistance(this.pos.lat, this.pos.lon, this.data.x, this.data.y)*10)/10
      },

      async getPosition() {
        const result = await new Promise(resolve => {
          navigator.geolocation.getCurrentPosition(d=>{
            resolve({
              lat: d.coords.latitude,
              lon: d.coords.longitude
            })
          })
        })
        this.pos.lat = result.lat
        this.pos.lon = result.lon
      },

      getDistance(lat1,lon1,lat2,lon2) {
        function deg2rad(deg) {
          return deg * (Math.PI/180)
        }
        const R = 6371
        const dLat = deg2rad(lat2-lat1)
        const dLon = deg2rad(lon2-lon1)
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
        const d = R * c
        return d
      },

      share() {
        navigator.share({
          title: 'Découvre cette pute sur Putefinder',
          url: document.location.href
        })
      },

      back() {
        store.commit('SET_LIGHT', store.state.all.slice(0,200))
        this.$router.push({ path: '/' })
      }
    },
    components: {
      Loader
    }
  }
</script>

<style scoped>

  #btnAddPute, #btnCancelAddPute {
      left: 15px;
      color: #fff;
      border-radius: 15px;
      margin: auto;
      width: 200px;
      z-index: 2;
      -webkit-box-shadow: 2px 2px 2px #aaa;
      box-shadow: 2px 2px 2px #aaa;
      padding: 8px 45px;
      background-color: #19b5fe;
  }

  #btnBack{
    right: 15px;
    color: #fff;
    border-radius: 15px;
    margin: auto;
    width: 200px;
    z-index: 2;
    -webkit-box-shadow: 2px 2px 2px #aaa;
    box-shadow: 2px 2px 2px #aaa;
    padding: 8px 45px;
    background-color: #19b5fe;
  }

  .phone-viewport {
    width: 100%;
    background-color: black;
    height: 50px;
    color: white;
    text-align: left;
  }

  .phone-viewport div{
    padding-top: 18px;
    padding-left: 35px;
    font-size: 25px;
  }
</style>