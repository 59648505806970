<template>
  <div style="height: 800px; width: 100%">
    <Loader v-if="!$store.state.position.lat || $store.state.all.length === 0"/>
    <span v-else>
      <div style="z-index: 99999;" v-if="modalAdd" id="modalFormAdd">
        <h2>Ajouter une pute</h2>
        <div id="formSubmit">
          <form>
            <div>
              <label for="shortdesc">
                Courte description: (Quelques mots)
              </label>
              <input v-model="shortdesc" id="shortdesc" class="formInput" required="required" type="text" placeholder="Courte description" name="shortdesc"/>
            </div>
            <div>
              <label for="desc">
                Description complète: (Localisation, horraires, conseils ?)
              </label>
              <textarea v-model="desc" required="required" placeholder="Description" name="desc"></textarea>
            </div>
            <div>
              <div id="captcha">
                <div render="explicit" class="g-recaptcha"></div>
              </div>
              <br/>
              <input required="required" type="checkbox" id="cguaddpute" />
              <label for="cguaddpute">
                Je confirme sur l'honneur que ma pute est valide.
              </label>
            </div>
            <div style="text-align: center;">
              <span id="btnSubmit" @click="publish">Soumettre</span>
              <span id="btnSubmit" @click="modalAdd = false">Retour</span>
            </div>
          </form>
        </div>
        <div id="addconsignes">
          <p>
            Merci d'être le plus précis possible. Pas de fautes, pas de vulgaritées, et votre pute sera approuvée !
          </p>
        </div>
      </div>
      <div id="modalAdd" v-if="addPopup">
        <div id="modalSub">
          <div id="animation">
            <img id="gpsanimation" src="icons/gps.png" width="30"/>
          </div>
          <div id="modalLegend">
            Clique sur la carte pour indiquer la position de la pute.
            Sois le plus précis possible !
          </div>
        </div>
      </div>
      <span v-if="!addMode && !modalAdd" @click="addOne" id="btnAddPute">Ajouter +</span>
      <span v-if="addMode && !modalAdd" @click="cancelAdd" id="btnCancel">Annuler</span>
      <span v-if="!modalAdd" @click="goToCenter" id="btnPosition"><img src="icons/gps.png" width="30"/></span>
      <l-map
        ref="map"
        :zoom="zoom"
        :center="[$store.state.position.lat,$store.state.position.lon]"
        :options="mapOptions"
        style="height: 150%;"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        v-on:click="addMarker"
      >
        <l-tile-layer
          :url="url"
        />
        <l-marker v-if="addPosition" :lat-lng="[addPosition.lat, addPosition.lon]" ref="markersRef">
          <l-icon
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
            icon-url="icons/marker.png" >
          </l-icon>
          <l-popup :closeButton="false" :permanent="true"><button style="padding: 10px; border: 1px solid black; border-radius: 5px;" @click="addHere">Ajouter ici</button></l-popup>
        </l-marker>
        <l-marker v-for="p of $store.state.light" v-bind:key="p.id" :lat-lng="[p.x, p.y]" @click="show(p)">
          <l-icon
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
            icon-url="icons/marker.png" >
          </l-icon>
          <l-tooltip>{{p.short}}</l-tooltip>
        </l-marker>
      </l-map>
    </span>
  </div>
</template>

<script>
import store from "@/store/index"
import Loader from '@/components/Loader.vue'
import { latLng } from "leaflet"
import { LMap, LTileLayer, LMarker, LTooltip, LIcon, LPopup } from "vue2-leaflet"
const putefinder = require('@/libs/putefinder')

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
    LPopup,
    Loader
  },
  mounted() {
    this.all = store.state.all
    this.light = store.state.light
  },
  data() {
    return {
      modalAdd: false,
      all: [],
      light: [],
      zoom: 14,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      shortdesc: "",
      desc: "",
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      iconSize: 26,
      addPopup: false,
      addMode: false,
      addPosition: null
    }
  },
  methods: {
    async publish() {
      const data = {
        shortdesc: this.shortdesc,
        longdesc: this.desc,
        captcha: document.querySelector('#g-recaptcha-response').value,
        honneur: true,
        x: this.addPosition.lat,
        y: this.addPosition.lon
      }
      const formData  = new FormData()
      for(const name in data) {
        formData.append(name, data[name])
      }

      const response = await fetch("https://api.putefinder.net/?a=add", {
        method: 'POST',
        body: formData
      })
      const result = await response.json()

      if(result.errors){
        alert(result.errors)
      }else{
        this.modalAdd = false
        location.reload()
      }
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom
      if(zoom < 14 && zoom > 12) {
        store.commit('SET_LIGHT', store.state.all.slice(0,150))
      }
      if(zoom < 12 && zoom > 10) {
        store.commit('SET_LIGHT', store.state.all.slice(0,200))
      }
      if(zoom < 10 && zoom > 8) {
        store.commit('SET_LIGHT', store.state.all.slice(0,300))
      }
      if(zoom < 8) {
        store.commit('SET_LIGHT', store.state.all)
      }
    },
    centerUpdate(center) {
      this.currentCenter = center
      const p = (Math.round(100*(Math.abs(center.lat-store.state.position.lat) + Math.abs(center.lng-store.state.position.lon))))

      if(p > 1 && p < 2) {
        store.commit('SET_LIGHT', store.state.all.slice(0,150))
      }
      if(p > 2 && p < 3) {
        store.commit('SET_LIGHT', store.state.all.slice(0,200))
      }
      if(p > 3 && p < 6) {
        store.commit('SET_LIGHT', store.state.all.slice(0,300))
      }
      if(p > 6) {
        store.commit('SET_LIGHT', store.state.all)
      }
    },
    showLongText() {
      this.showParagraph = !this.showParagraph
    },
    show(p) {
      this.$router.push({ path: '/view/'+p.id+'-'+this.formatUrl(p.short)+'.htm' })
    },
    formatUrl(short) {
      return putefinder.formatUrl(short)
    },
    goToCenter() {
      this.$refs.map.mapObject.setView([this.$store.state.position.lat,this.$store.state.position.lon], this.zoom)
    },
    addOne() {
      this.addPopup = true
      this.addMode = true
      this.$refs.map.mapObject.setView([this.$store.state.position.lat,this.$store.state.position.lon], this.zoom + 1)
      setTimeout(()=>{
        this.addPopup = false
      }, 6000)
    },
    addHere() {
      this.modalAdd = true
    },
    cancelAdd() {
      this.addMode = false
      this.addPopup = false
      this.addPosition = null
    },
    addMarker(data) {
      if(!this.addMode) {
        return
      }
      this.addPosition = {
        lat: data.latlng.lat,
        lon: data.latlng.lng
      }
      this.addPopup = false
      setTimeout(()=>{
        const tooltip = this.$refs.markersRef
        tooltip.mapObject.openPopup()
      },10)
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.52]
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize * 1.42]
    }
  }
}
</script>


<style>

  @keyframes bouge {
    from {
      padding-top: 20px;
    }

    to {
      padding-top: 0px;
    }
  }

  body {
    overflow: hidden;
  }

  .formInput{
    padding: 5px;
    width: 90%;
    height: 40px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 25px;
  }

  textarea{
    padding: 5px;
    width: 90%;
    height: 100px;
    border: 1px solid grey;
    border-radius: 5px;
  }

  label{
    font-size: 16px;
    display: block;
    padding: 10px;
  }

  #modalFormAdd{
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 999;
    height: 100%;
  }

  .leaflet-popup{
    margin-top: -50px;
  }

  #modalLegend{
    padding-top: 180px;
    font-size: 18px;
    height: 280px;
  }

  #cguaddpute {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
    display: inline;
  }

  label{
    display: inline-block;
  }

  #captcha{
    text-align: center;
    margin: auto;
    width: 300px;
  }
  
  #btnSubmit{
    border: 1px solid grey;
    border-radius: 5px;
    background-color: #fff;
    padding: 8px 45px;
    width: 300px;
    display: block;
    margin: auto;
    margin-top: 15px;
    font-size: 16px;
  }

  #modalAdd{
    text-align: center;
    position: absolute;
    z-index: 999;
    width: 100%;
  }

  #modalAdd #modalSub{
    padding-top: 25px;
    background-color: white;
    width: 300px;
    text-align: center;
    margin: auto;
    border-radius: 5px;
    margin-top: 130px;
  }

  #modalAdd div{
    width: 300px;
  }

  #animation{
    position: absolute;
    width: 100%;
    text-align: center;
  }

  #gpsanimation{
    margin: auto;
    text-align: center;
    animation: bouge .3s infinite alternate-reverse;
    width: 130px;
  }
  #btnAddPute{  
    left: 15px;
    color: #fff;
    border-radius: 15px;
    position: absolute;
    z-index: 999;
    bottom: 100px;
    background-color: #19b5fe;
    -webkit-box-shadow: 2px 2px 2px #aaa;
    box-shadow: 2px 2px 2px #aaa;
    padding: 10px 45px;
  }
  #addconsignes{
    margin-top: 30px;
    font-size: 18px;
  }
  #btnCancel{  
    left: 15px;
    color: #fff;
    border-radius: 15px;
    position: absolute;
    bottom: 50px;
    z-index: 999;
    bottom: 100px;
    background-color: red;
    -webkit-box-shadow: 2px 2px 2px #aaa;
    box-shadow: 2px 2px 2px #aaa;
    padding: 10px 45px;
  }
  #btnPosition{
    right: 15px;
    border-radius: 15px;
    position: absolute;
    color: #fff;
    z-index: 999;
    background-color: #19b5fe;
    bottom: 100px;
    padding: 10px;
  }
</style>