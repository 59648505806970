<template>
  <div class="arround">
    <NavBar/>
    <ArroundMe/>
    <BottomBar/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import BottomBar from '@/components/BottomBar.vue'
import ArroundMe from '../components/ArroundMe.vue'

export default {
  name: 'Arround',
  components: {
    BottomBar,
    NavBar,
    ArroundMe
  }
}
</script>
