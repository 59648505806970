<template>
  <div id="loader">
    <img id="logo_black" src="/logo_white.svg" alt="putefinder logo" /><br/>
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader'
  }
</script>

<style>
  html{
    background-color: rgb(243, 77, 207) !important;
  }

  #logo_black{
    width: 300px;
  }

  #loader h1{
    color: white;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 42px;
  }

  #loader h2{
    color: white;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 32px;
  }

  #loader{
    margin-top: 22%;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(255, 253, 255);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
</style>
