module.exports = {
    baseUrl: "https://api.putefinder.net",

    async getAll() {
        const response = await fetch(this.baseUrl+'/?a=getall')
        return await response.json()
    },

    async add(payload) {
        const response = await fetch(this.baseUrl + '/?a=getall', {
            method: "POST",
            body: JSON.stringify(payload)
        })
        return await response.json()
    }
}