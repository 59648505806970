<template>
  <div>
    <Loader v-if="$store.state.all.length === 0"/>
      <md-card v-for="p in $store.state.all" v-bind:key="p.id">
      <router-link :to="'/view/'+p.id+'-montpellier-'+formatUrl(p.short)+'.htm'">
        <div>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">{{p.short}}</div>
              <div class="md-subhead">{{p.distance}} km</div>
            </md-card-header-text>
            <md-card-media>
              Dummy
            </md-card-media>
          </md-card-header>
        </div>
      </router-link>
    </md-card>
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import store from "@/store/index"
  const putefinder = require('@/libs/putefinder')

  export default {
    name: 'ArroundMe',
    data() {
      return {
        all: []
      }
    },
    mounted() {
      this.all = store.state.all
    },
    methods: {
      formatUrl(short) {
        return putefinder.formatUrl(short)
      }
    },
    components: {
      Loader
    }
  }
</script>

<style scoped>
  .phone-viewport {
    width: 100%;
    background-color: black;
    height: 50px;
    color: white;
    text-align: left;
  }

  .phone-viewport div{
    padding-top: 18px;
    padding-left: 35px;
    font-size: 25px;
  }
</style>