import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    position: {
      lat: null,
      lon: null
    },
    all: [],
    light: []
  },
  mutations: {
    SET_POSITION (state, payload) {
      state.position = payload
    },

    SET_ALL (state, payload) {
      state.all = payload
    },

    SET_LIGHT (state, payload) {
      state.light = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
