<template>
  <div id="navbar">
    <div class="phone-viewport">
      <img src="/logo_white.svg" alt="putefinder logo" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavBar'
  }
</script>

<style scoped>
  .phone-viewport {
    width: 100%;
    background-color: black;
    height: 50px;
    color: white;
    text-align: left;
    position: fixed;
    z-index: 2;
  }

  .phone-viewport img{
    padding-left: 25px;
    width: 190px;
  }

  #navbar {
    height: 50px;
    z-index: 9999;
  }
</style>