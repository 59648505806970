<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import store from "@/store/index"
const putefinder = require('@/libs/putefinder')

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      /*
       * Get current pos
       */
      const result = await putefinder.getLocation()
      const pos = {
        lat: result.lat,
        lon: result.lon
      }
      store.commit('SET_POSITION', pos)

      /*
       * Get all
       */
      const all = await putefinder.getAll()
      store.commit('SET_ALL', all)

      putefinder.order(all, pos)
      store.commit('SET_LIGHT', all.slice(0,200))
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
